import React from "react";
import {
  SEO,
  MarkdownContent,
  DynamicForm,
  Accordion,
} from "@bluefin/components";
import { Grid, Segment, Icon } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class LoyaltyProgramPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      fishermanWebsiteComponent,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid className={"custom-page-grid"} style={{}} stackable={true}>
          <Grid.Row
            className={""}
            style={{ padding: "40" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={16}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e0e1a98b-a7b2-40e6-acbe-d4ffd2ef39dc",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"mqfuniashmepbemk"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ptsjsxgerrcezpga"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "43deb702-c49f-4760-8085-c3be1cec0a46",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ouhmhxzmjdtqpone"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"uxengfmhkpijzfaf"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={1358}
                  businessInfoId={6540}
                  websiteId={7601}
                  locationId={1426}
                  componentConfigurationId={77532}
                  header={fishermanWebsiteComponent.data.header}
                  fields={fishermanWebsiteComponent.data.dynamicFormFields}
                  consentSettings={
                    fishermanWebsiteComponent.data.consentSettings
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"zhrwtildylxgtdep"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"qwzmwymbcwjgamzb"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "9ed28505-dcd9-4919-bea1-40fe8dac6fb6",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={12}
              textAlign={"left"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Accordion
                  className={""}
                  style={{}}
                  styled={true}
                  exclusive={true}
                  defaultActiveIndexes={[0]}
                >
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "5302dcaf-dd5b-4867-bf1e-0ed51e5a440e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "bc70c0ce-db52-4c83-915a-ee18e3235243",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "cfb7fa48-687a-44f6-a9cc-34a6c5614a02",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "8462beb0-fa1c-481c-b0eb-13de47ec97a4",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "aa798384-fa43-49f0-8cf9-2cf07c1953d6",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "122d3854-07dd-48f6-9776-d1a81d51f0ae",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                  <Accordion.Title>
                    <Icon name={"dropdown"} />
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "766fdffc-b4e2-4714-8d1f-14911e62244e",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Title>
                  <Accordion.Content>
                    <MarkdownContent
                      className={"text-block"}
                      style={{}}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentName: "MarkdownContent",
                        componentId: "90c638af-0270-4ac5-999f-1cb2bb5615d9",
                        defaultValue: "",
                      })}
                    />
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "93c64559-ff85-4d4b-98f6-9993177867e5",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27492 }) {
      title
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "6b3602e0-09bd-4bde-bd20-5c2e8ac9e0d0" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
        }
        consentSettings {
          show
          required
        }
      }
    }
  }
`;
